html,
body,
#app,
#app>div,
#root {
    height: 100%;
    margin: 0;
}

@media only screen and (min-width: 768px) {
    /* For desktop: */
    body {
        max-width: 400px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }
  }

#SC {
    background-color: #e2cba1;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-image: url('./static/background.jpg');
}

.page {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
}

.loadingScreen {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    background-color: honeydew;
}

#keywordForm {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-height: 30%;
    align-items: center;
    margin: 0 0.5em 0 0.5em;
    width: 100%;
}

#keywordForm input {
    margin: 1em;
}

#keywordForm label {
    text-align: center;
}

#resultText {
    text-align: center;
    display: flex;
    flex-direction: column;
}

.clue {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 1em;
    flex-wrap: wrap;
}

img {
    max-width: 100%;
}

.break {
    flex-basis: 100%;
    height: 1em;
}

.keywordSearch {
    width: 100%;
    max-height: 5em;
    display: flex;
    background-color: #ffd186;
    align-items: center;
    top: 0;
    position: fixed;
}

.minigameKeywordSearch {
    width: 100%;
    max-height: 5em;
    display: flex;
    background-color: #ffd186;
    align-items: center;
    top: 0;
}

#logout {
    padding: 0.5em;
}

#navbar {
    width: 100%;
    display: flex;
    bottom: 0;
    left: 0;
}

.navButton {
    width: 20%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    border: solid 1px #ffd186;
    margin: 0.3em;
    cursor: pointer;
    max-width: 4em;
    color: #ffd186;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

#navContainer {
    display: flex;
    width: 100%;
    height: 4em;
    background-color: black;
    justify-content: center;
}

.spacer {
    margin: 0.5em;
}

#mapBox {
    background-color: white;
}

.settingsMenu {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    background-color: rgba(226, 203, 161, 0.95);
    font-size: 1.5;
    font-family: helvetica;
}

.settingsMenu a {
    color: black;
    text-decoration: none;
}

.settingsMenuHeader {
    padding: 0.5em;
    font-size: 2em;
}

.settingList {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.settingCategory {}

.settingCategoryHeading {
    padding: 0.5em;
    font-weight: initial;
}

.settingCategoryList {
    margin-bottom: 1em;
}

.settingCategoryListItem {
    margin: 1em;
    border-bottom: solid 1px;
    padding-bottom: 0.5em;
    cursor: pointer;
}

.logOut {
    display: flex;
    justify-content: center;
    padding-bottom: 1em;
    cursor: pointer;
}

.opaqueTrickery {
    background-color: rgba(226, 203, 161, 1.0);
    width: auto;
    display: inline-block;
    padding: 0.2em;
}

.hiddenPage {
    display: none !important;
}

.hiddenMapPage {
    position: absolute;
    opacity: 0;
    z-index: 0;
    left: -100%;
}

.hiddenClue {
    display: none !important;
}

img.clue {
    margin: 0;
}


/* Snake */

#snakeControls {
    position: absolute;
    right: 0;
    bottom: 10vh;
    color: white;
    display: flex;
    flex-direction: column;
}

#snakeTopRow {
    display: flex;
    justify-content: center;
}

#snakeMiddleRow {
    display: flex;
    justify-content: space-evenly;
}

#snakeBottomRow {
    display: flex;
    justify-content: center;
}

.snakeButton {
    background-color: gray;
    padding: 0.5em;
    margin: 0.5em;
    width: 3em;
    text-align: center;
    cursor: pointer;
}

.loginForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 80%;
    text-align: center;
    max-height: 70%;
    overflow-y: auto;
    padding: 1.5em;
    background: url('./static/TreasureMap.png');
    background-size: 100% 100%;
}

.loginForm label {
    margin: 0.5em 0 0.5em 0;
}

.loginForm button {
    margin: 0.5em 0 0.5em 0;
    width: 100%;
}

.loginForm input {
    margin: 0.5em 0 0.5em 0;
    width: 100%;
    box-sizing: border-box;
}

.loginCheckbox {
    justify-content: space-between;
    width: 100%;
}

#staySignedIn {
    width: unset;
}

#agreeToTerms {
    width: unset;
}

.infoBox {
    width: 80%;
    text-align: center;
    max-height: 70%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: 1em;
    background: url('./static/TreasureMap.png');
    background-size: 100% 100%;
    min-height: 10em;
    position: absolute;
    justify-content: center;
}

.previousClues {
    padding: 0.5em;
    cursor: pointer;
    position: sticky;
    top: 0em;
    text-align: left;
    background-color: white;
}

.previousCluesWrapper {
    overflow-y: auto;
}

.previousCluesBox {
    overflow-y: auto;
}

.previousCluesBox #resultText {
    margin: 0.6em;
    border-bottom: 2px solid lightgray;
}

.previousClueNumber {
    text-align: left;
}

#keywordBox .page {
    padding-top: 3em;
}

.smallLoadingSpinner {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0.5em;
    border: 0.3em solid #3498db;
    border-radius: 50%;
    border-top: 0.3em solid #cdcdcd;
    border-right: 0.3em solid #cdcdcd;
    width: 1em;
    height: 1em;
    -webkit-animation: spin 1s linear infinite;
    /* Safari */
    animation: spin 1s linear infinite;
    z-index: 2;
}

.bigLoadingSpinner {
    position: absolute;
    top: calc(50% - 3em);
    left: calc(50% - 3em);
    transform: translate(-50%, -50%);
    margin: 0.5em;
    border: 0.3em solid #3498db;
    border-radius: 50%;
    border-top: 0.3em solid #cdcdcd;
    border-right: 0.3em solid #cdcdcd;
    width: 5em;
    height: 5em;
    -webkit-animation: spin 1s linear infinite;
    /* Safari */
    animation: spin 1s linear infinite;
    z-index: 2;
}


/* Safari */

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.switchLoginButton {
    cursor: pointer;
    text-decoration: underline;
    color: blue;
    margin: 0.5em 0 0.5em 0.5em;
   }
   
.flexer {
    display: flex;
}